import React from "react";
import { withTranslation } from "react-i18next";
import ImageMilestone1 from "../../assets/MuLanding/Image-Milestones1.png";
import ImageMilestone2 from "../../assets/MuLanding/Image-Milestones2.png";
import ImageMilestone3 from "../../assets/MuLanding/Image-Milestones3.png";
import RedArrowRight from "../../assets/MuLanding/icon-arrow-right-red.png";

const ThreeImageRow = ({ t }) => {
  return (
    <div className="three-image-row">
      <Image image={ImageMilestone1} />
      <Image image={ImageMilestone2} />
      <Image image={ImageMilestone3} />
      <Content
        first={true}
        title={t("milestone_title_1")}
        description={t("milestone_description_1")}
      />
      <Content
        title={t("milestone_title_2")}
        description={t("milestone_description_2")}
        doubleArrow={true}
      />
      <Content
        title={t("milestone_title_3")}
        description={t("milestone_description_3")}
        doubleArrow={true}
      />
    </div>
  );
};

const Image = ({ image, center }) => {
  return (
    <div className="image-row-content">
      <img src={image} className={`${center ? "center-image" : ""}`} />
    </div>
  );
};

const Content = ({ title, description, doubleArrow, first }) => {
  return (
    <div className="image-row-content">
      <div className={`${first ? "first-image-content " : ""}`}>
        {doubleArrow && (
          <img
            src={RedArrowRight}
            alt=""
            className="fade-arrow-icon"
            style={{ opacity: "50%" }}
          />
        )}
        <img src={RedArrowRight} alt="" className="small-arrow-icon" />
        <div>
          <h3 className="title">{title}</h3>
          <p
            className="description"
            dangerouslySetInnerHTML={{
              __html: description.replace(/(\r\n|\n|\r)/gm, "<br/>"),
            }}
          ></p>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(ThreeImageRow);
