import React from "react";
import ArrowDown from "../../assets/MuLanding/icon-arrow-down.webp";

const CollapsibleTitle = ({ title }) => {
  return (
    <div className="collapsible-trigger">
      <p>{title}</p>
      <img className="collapsible-trigger-img" src={ArrowDown} alt="arrow" />
    </div>
  );
};

export default CollapsibleTitle;
