import React, { useState, useRef, useEffect } from "react";
import { withTranslation } from "react-i18next";
import Lottie from "lottie-react";
import enSrtMobile from "../../assets/videoSrt/en/MU_Landing_Page_EN_4x5.vtt";
import enSrtDesktop from "../../assets/videoSrt/en/MU_Landing_Page_EN_16x9.vtt";
import scSrtMobile from "../../assets/videoSrt/sc/MU_Landing_Page_SC_4x5.vtt";
import scSrtDesktop from "../../assets/videoSrt/sc/MU_Landing_Page_SC_16x9.vtt";
import tcSrtMobile from "../../assets/videoSrt/tc/MU_Landing_Page_TC_4x5.vtt";
import tcSrtDesktop from "../../assets/videoSrt/tc/MU_Landing_Page_TC_16x9.vtt";
import PlayButton from "../../assets/MuLanding/Icon_Play_White.json";
import useBrowser from "../../hook/useBroswer";
import VideoPlayer from "./videoPlayer";

const mobileVideo =
  "https://career.doogroup.com/wp-content/uploads/2023/06/DOO-Group_Launch_4x5_Clean.mp4";
const desktopVideo =
  "https://career.doogroup.com/wp-content/uploads/2023/06/DOO-Group_Launch_16x9_Clean.mp4";

const Banner = ({ t, i18n }) => {
  const { isMobile, browser, isAppleChrome } = useBrowser();
  const [showPlayer, setShowPlayer] = useState({
    desktop: false,
    mobile: false,
  });
  const [pause, setPause] = useState(false);

  const mobileVideoRef = useRef();
  const desktopVideoRef = useRef();
  const desktopVideoJSRef = useRef();

  const mobileSubtitle = [
    {
      label: "English",
      kind: "captions",
      srcLang: "en",
      default: i18n.language === "en",
      src: enSrtMobile,
    },
    {
      label: "繁体中文",
      kind: "captions",
      srcLang: "tc",
      default: i18n.language === "tc",
      src: tcSrtMobile,
    },
    {
      label: "简体中文",
      kind: "captions",
      srcLang: "zh",
      default: i18n.language === "zh",
      src: scSrtMobile,
    },
  ];
  const desktopSubtitle = [
    {
      label: "English",
      kind: "captions",
      srcLang: "en",
      srclang: "en",
      default: i18n.language === "en",
      src: enSrtDesktop,
    },
    {
      label: "繁体中文",
      kind: "captions",
      srcLang: "tc",
      srclang: "tc",
      default: i18n.language === "tc",
      src: tcSrtDesktop,
    },
    {
      label: "简体中文",
      kind: "captions",
      srcLang: "zh",
      srclang: "zh",
      default: i18n.language === "zh",
      src: scSrtDesktop,
    },
  ];

  const videoJsOptions = {
    autoplay: true,
    controls: true,
    onReady: (player) => {
      desktopVideoJSRef.current = player;
      player.on("pause", () => {
        setPause(true);
      });
      player.on("play", () => {
        setPause(false);
      });
      player.on("ended", () => {
        setShowPlayer({ desktop: false, mobile: false });
      });
    },
    tracks: desktopSubtitle,
    sources: [
      {
        src: desktopVideo,
        type: "video/mp4",
      },
    ],
  };
  //to make sure video is able to fit in
  const [videoHeight, setVideoHeight] = useState(0);
  const calHeight = () => {
    setVideoHeight((window.innerWidth * 9) / 16);
  };
  useEffect(() => {
    calHeight();
    window.addEventListener("resize", calHeight);
    return () => {
      window.removeEventListener("resize", calHeight);
    };
  }, []);

  const changeSubtitleLanguage = (track) => {
    for (let index = 0; index < track.length; index++) {
      if (track[index].language === i18n.language) track[index].mode = "showing";
      else track[index].mode = "disabled";
    }
  };

  useEffect(() => {
    if (desktopVideoRef.current) changeSubtitleLanguage(desktopVideoRef.current.textTracks);
    if (mobileVideoRef.current) changeSubtitleLanguage(mobileVideoRef.current.textTracks);
    if (desktopVideoJSRef.current) changeSubtitleLanguage(desktopVideoJSRef.current.textTracks());
    return () => {};
  }, [i18n.language]);

  return (
    <div>
      <div className="isMobile">
        <div
          style={{
            display: showPlayer.mobile ? "flex" : "none",
            position: "relative",
          }}
        >
          {
            <video
              className={isMobile && browser === "Apple" ? "" : "mu-video"}
              ref={mobileVideoRef}
              onPlaying={() => {
                setPause(false);
              }}
              onPause={() => {
                setPause(true);
              }}
              onEnded={() => {
                setShowPlayer({ desktop: false, mobile: false });
              }}
              onClick={(e) => {
                e.target.pause();
              }}
              onTouchEnd={(e) => {
                e.target.pause();
              }}
              preload="auto"
              controls={true}
              style={{ width: "100%", height: "100%" }}
              controlsList="noplaybackrate nodownload nofullscreen"
              disablePictureInPicture={true}
            >
              <source src={mobileVideo} type="video/mp4"></source>
              {mobileSubtitle.map((item) => (
                <track key={item.label} {...item} />
              ))}
            </video>
          }
          {pause && (
            <div className="overlay-prevent-click">
              <Lottie
                animationData={PlayButton}
                loop={true}
                className="play-button-video"
                onClick={() => {
                  setPause(false);
                  if (mobileVideoRef.current) mobileVideoRef.current.play();
                }}
              />
            </div>
          )}
        </div>
      </div>
      <div className="isDesktop">
        <div
          className="player-wrapper"
          style={{
            display: showPlayer.desktop ? "block" : "none",
            height: videoHeight,
          }}
        >
          {!isAppleChrome ? (
            <video
              ref={desktopVideoRef}
              className="mu-video"
              onPlaying={() => {
                setPause(false);
              }}
              onPause={() => {
                setPause(true);
              }}
              onEnded={() => {
                setShowPlayer({ desktop: false, mobile: false });
              }}
              onClick={(e) => {
                e.target.pause();
              }}
              onTouchEnd={(e) => {
                e.target.pause();
              }}
              preload="auto"
              controls={true}
              style={{ width: "100%", height: "100%" }}
              controlsList="noplaybackrate nodownload nofullscreen"
              disablePictureInPicture={true}
            >
              <source src={desktopVideo} type="video/mp4"></source>
              {desktopSubtitle.map((item) => (
                <track key={item.label} {...item} />
              ))}
            </video>
          ) : (
            <VideoPlayer {...videoJsOptions}></VideoPlayer>
          )}

          {pause && (
            <div className="overlay-prevent-click">
              <Lottie
                animationData={PlayButton}
                loop={true}
                className="play-button-video"
                onClick={() => {
                  setPause(false);
                  if (desktopVideoRef.current) desktopVideoRef.current.play();
                  if (desktopVideoJSRef.current) desktopVideoJSRef.current.play();
                }}
              />
            </div>
          )}
        </div>
      </div>
      <div
        className="landing-banner"
        style={{
          display: showPlayer.desktop || showPlayer.mobile ? "none" : "flex",
        }}
      >
        <h2 className="landing-title">
          {i18n.language === "en" ? (
            <>
              <span className="landing-title-1">{t("landing_title_1")}</span>
              <span className="landing-title-2">{t("landing_title_2")}</span>
            </>
          ) : (
            <>
              <span className="landing-title-cn-1">{t("landing_title_1")}</span>
              <span className="landing-title-cn-2">{t("landing_title_2")}</span>
            </>
          )}
        </h2>
        <h1 className="landing-title-3">{t("landing_title_3")}</h1>
        <Lottie
          animationData={PlayButton}
          loop={true}
          className="play-button"
          onClick={() => {
            setPause(false);
            if (window.innerWidth < 600) {
              setShowPlayer({ desktop: false, mobile: true });
              if (mobileVideoRef.current) mobileVideoRef.current.play();
            } else {
              setShowPlayer({ desktop: true, mobile: false });
              if (desktopVideoRef.current) desktopVideoRef.current.play();
              if (desktopVideoJSRef.current) desktopVideoJSRef.current.play();
            }
          }}
        />
      </div>
    </div>
  );
};

export default withTranslation()(Banner);
