function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function setCookie(key, value, expiresDay, path, domain, secure) {
  if (!key || /^(?:expires|max\-age|path|domain|secure)$/i.test(key))
    return false;
  var setArr = [encodeURIComponent(key) + "=" + encodeURIComponent(value)];
  if (domain) setArr.push("domain=" + domain);
  if (path) setArr.push("path=" + path);
  if (secure) setArr.push("secure");
  if (expiresDay) {
    var expires = new Date();
    expires.setTime(expires.getTime() + expiresDay * 24 * 60 * 60 * 1000);
    setArr.push("expires=" + expires.toUTCString());
  } else if (expiresDay === 0) {
    setArr.push("expires=Thu, 01 Jan 1970 00:00:00 GMT");
  }
  document.cookie = setArr.join("; ");
  return true;
}

function getSearch() {
  let searchStr = (window.location.search || window.location.href).replace(
    /^.*\?/,
    ""
  );
  let searchArr = searchStr.split("&");
  let result = {};
  for (var i = 0; i < searchArr.length; i++) {
    var s = searchArr[i];
    var keyValue = s.split("=");
    if (keyValue[0] != searchStr) {
      result[keyValue[0]] = keyValue[1];
    }
  }
  return result;
}

export { getCookie, setCookie, getSearch };
