import React from "react";
import { withTranslation } from "react-i18next";
import globeIcon from "../../assets/MuLanding/globe-icon.webp";
import arrowDown from "../../assets/MuLanding/icon-arrow-down.webp";
import { NavLink } from "react-router-dom";
const LangBar = ({ t, i18n }) => {
  
  return (
    <nav className="nav-bar">
      <div>
        <img src={globeIcon} alt="globe" className="globe" />
        <p>{t("language")}:</p>
        <div className="dropdown">
          <div className="language-text">
            <p>{i18n.language === "en" ? t("en") : i18n.language === "zh" ? t("zh") : t("tc")}</p>
            <img src={arrowDown} alt="arrow down" />
          </div>
          <div className="dropdown-content">
            <NavLink
              to={`/en/partnership/manutd`}
              onClick={() => {
                i18n.changeLanguage("en");
              }}
            >
              {t("en")}
            </NavLink>
            <NavLink
              to={`/zh/partnership/manutd`}
              onClick={() => {
                i18n.changeLanguage("zh");
              }}
            >
              {t("zh")}
            </NavLink>
            <NavLink
              to={`/tc/partnership/manutd`}
              onClick={() => {
                i18n.changeLanguage("tc");
              }}
            >
              {t("tc")}
            </NavLink>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default withTranslation()(LangBar);
