import React, { useState, useEffect, useRef, Fragment } from "react";
import { withTranslation } from "react-i18next";
import ImageLogo from "../../assets/MuLanding/header-logo.png";
import ImageMobileLogo from "../../assets/MuLanding/mobile/header-logo.png";
import Hamburger from "../../assets/Icon_Menu.png";
import RedArrowRight from "../../assets/MuLanding/icon-arrow-right-red.png";
import globeIcon from "../../assets/MuLanding/mobile/globe-icon.png";
import arrowDown from "../../assets/MuLanding/mobile/icon-arrow-right.png";
import { NavLink } from "react-router-dom";
const Header = ({ t, i18n }) => {

  const headerLink = [
    {
      href: "about",
      title: t("about"),
    },
    {
      href: "milestones",
      title: t("milestones"),
    },
    {
      href: "values",
      title: t("mu_values"),
    },
    {
      href: "highlights",
      title: t("highlights"),
    },
  ];
  const target = [...headerLink].reverse().map((item) => item.href);
  const [contentId, setContentId] = useState("about");
  const [position, setPosition] = useState([]);
  const [languageMenu, setLanguageMenu] = useState(false);

  const mobileMenuCheckBox = useRef();

  useEffect(() => {
    if (typeof document !== "undefined") {
      target.forEach((item, index) => {
        let offsetTop = document.getElementById(item).offsetTop;

        if (index !== 3) {
          //fixed header offset
          offsetTop = offsetTop - 180;
        }
        setPosition((prev) => [...prev, offsetTop]);
      });
    }
  }, []);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [position]);

  const handleScroll = () => {
    if (typeof window !== "undefined") {
      const currentPosition = window.scrollY;
      for (let i = 0; i < position.length; i++) {
        if (currentPosition > position[i]) {
          setContentId(target[i]);
          break;
        }
      }
    }
  };

  const triggerMenu = () => {
    if (mobileMenuCheckBox.current) {
      mobileMenuCheckBox.current.checked = !mobileMenuCheckBox.current.checked;
      //alway show header link
      if (mobileMenuCheckBox.current.checked) setLanguageMenu(false);
    }
  };

  const selectLanguage = () => {
    setLanguageMenu(true);
  };
  return (
    <>
      <div className="header-box">
        {headerLink.map(({ href, title }, index) => {
          if (index === Math.ceil(headerLink.length / 2))
            return (
              <Fragment key={title}>
                <img className="haeder-logo" src={ImageLogo} alt="MU x DOO" />
                <HeaderLink href={`#${href}`} title={title} selected={contentId === href} />
              </Fragment>
            );
          else
            return (
              <HeaderLink
                key={title}
                href={`#${href}`}
                title={title}
                selected={contentId === href}
              />
            );
        })}
      </div>
      <nav className="mobile-nav-bar mobile-header-box ">
        <input type="checkbox" style={{ display: "none" }} ref={mobileMenuCheckBox} id="menuBtn" />
        <img src={ImageMobileLogo} style={{ width: "232px" }}></img>
        <span onClick={triggerMenu}>
          <img src={Hamburger} className="hamburger" alt="hamburger icon"></img>
        </span>
        <div className="mobile-nav-content">
          {languageMenu ? (
            <>
              <div>
                <img src={RedArrowRight} alt="" />
                <p className="language-text">{t("language")}</p>
              </div>
              <MobileLanguageLink
                text={t("en")}
                href={`/en/partnership/manutd`}
                selected={i18n.language === "en"}
                closeMenu={() => {
                  triggerMenu();
                  i18n.changeLanguage("en");
                }}
              />
              <MobileLanguageLink
                text={t("zh")}
                href={`/zh/partnership/manutd`}
                selected={i18n.language === "zh"}
                closeMenu={() => {
                  triggerMenu();
                  i18n.changeLanguage("zh");
                }}
              />
              <MobileLanguageLink
                text={t("tc")}
                href={`/tc/partnership/manutd`}
                selected={i18n.language === "tc"}
                closeMenu={() => {
                  triggerMenu();
                  i18n.changeLanguage("tc");
                }}
              />
            </>
          ) : (
            <>
              {headerLink.map(({ href, title }) => (
                <MobileHeaderLink
                  href={`#${href}`}
                  title={title}
                  key={title}
                  selected={contentId === href}
                  closeMenu={triggerMenu}
                />
              ))}
              <hr />

              <div className="mobile-language-row" onClick={selectLanguage}>
                <img src={globeIcon} alt="globe" className="globe" />
                <p>{t("language")}:</p>
                <p>
                  {i18n.language === "en" ? t("en") : i18n.language === "zh" ? t("zh") : t("tc")}
                </p>
                <img src={arrowDown} alt="arrow down" />
              </div>
            </>
          )}
        </div>
      </nav>
    </>
  );
};

const MobileHeaderLink = ({ href, title, selected, closeMenu }) => {
  return (
    <div>
      <img src={RedArrowRight} alt="" />
      <a href={`${href}`} className={`${selected ? "menu-selected" : ""}`} onClick={closeMenu}>
        {title}
      </a>
    </div>
  );
};

const MobileLanguageLink = ({ href, selected, text, closeMenu }) => {
  return (
    <div>
      <NavLink
        className={`${selected ? "menu-selected" : ""} language-option`}
        onClick={closeMenu}
        to={href}
      >
        {text}
      </NavLink>
    </div>
  );
};

const HeaderLink = ({ title, href, selected }) => {
  return (
    <div className={`header-wrapper ${selected ? "menu-selected" : ""}`}>
      <a href={href} className="header-link">
        {title}
      </a>
      <div className={`underline`}></div>
    </div>
  );
};

export default withTranslation()(Header);
