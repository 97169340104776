import React from "react";
import { withTranslation } from "react-i18next";
import bannerImg from "../../assets/DG_x_MU_Banner_visual_new_02.png";
import LogoMUxDG from "../../assets/Logo_MUxDG_Vertical.png";

const Home = ({ t, i18n }) => {
  return (
    <section id="home" className="home">
      <div className="container_2560">
        <img src={bannerImg} className="containerBG" />
        <div className="content_wrapper">
          <div className="container">
            <div className="mu_banner">
              <img src={LogoMUxDG} />
              <p dangerouslySetInnerHTML={{ __html: t("title_MU") }}></p>
              <a href={`${i18n.language}/partnership/manutd`} target="_blank">
                {t("btn_knowMore")}
              </a>
            </div>
            <div className="exploreTradingFuture_banner">
              <h1>{t("title_label_1")}</h1>
              <p>{t("title_label_3")}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default withTranslation()(Home);
