import React from "react";
import Collapsible from 'react-collapsible';
import DooEntities from "./dooEntities";
import { withTranslation } from "react-i18next";
import CollapsibleTitle from "./collapsibleTitle";
const Tester = ({ t }) => {
    return (
        <div className="container isDesktop">
            <div className="table_border1">
                <Collapsible tabIndex={0} open trigger={<CollapsibleTitle title={t('doo_entity_notice')} />} className="entity_style margin_table">
                    <DooEntities />
                </Collapsible>
            </div>
        </div>
    );
}
export default withTranslation()(Tester);