import React, { useEffect, useState } from "react";
import LogoMUxDG from "../../assets/Logo_MUxDG_Horizontal.png";
import { withTranslation } from "react-i18next";
import LangChange from "./langChange";

const Navbar = ({ t, light }) => {
  const [isFloating, setIsFloating] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState("transparent");
  const changeBG = () => {
    if (window.scrollY >= (light ? 100 : 100)) {
      setIsFloating(true);
      setBackgroundColor("white");
    } else {
      setIsFloating(false);
      setBackgroundColor("transparent");
    }
  };

  useEffect(() => {
    document.addEventListener("scroll", changeBG);
    return () => {
      document.removeEventListener("scroll", changeBG);
    };
  }, []);

  return (
    <nav
      className={light ? "navbar navbar-light" : "navbar"}
      style={{ backgroundColor: backgroundColor, padding: isFloating ? "14px 0" : "32px 0px" }}
    >
      <div className="container">
        <div className="navbar-brand">
          <img
            className="responsive-img LogoMUxDG"
            src={light ? LogoMUxDG : LogoMUxDG}
            alt="doo holding logo"
          />
        </div>
        <ul className="navbar-nav">
          <li className="navbar-item">
            <a className="navbar-link" href={`#home`}>
              {t("home")}
            </a>
          </li>
          <li className="navbar-item">
            <a className="navbar-link" href={`#brands`}>
              {t("brands")}
            </a>
          </li>
          <li className="navbar-item">
            <a className="navbar-link" href={`#core-values`}>
              {t("values")}
            </a>
          </li>
          <li className="navbar-item">
            <a className="navbar-link" href={`#global-offices`}>
              {t("offices")}
            </a>
          </li>
          <li className="navbar-item">
            <a className="navbar-link" href={`#contact-us`}>
              {t("contact_us")}
            </a>
          </li>
          <LangChange light={light} />
        </ul>
      </div>
    </nav>
  );
};

export default withTranslation()(Navbar);
