import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "./style.css";
import { withTranslation } from "react-i18next";
import Collapsible from "react-collapsible";
import DooEntities from "./dooEntities";
import CollapsibleTitle from "./collapsibleTitle";

const Disclosure = ({ t }) => {
  return (
    <div>
      <Tabs className="isDesktop">
        <TabList>
          <Tab>{t("disclosure_label_1_1")}</Tab>
          {/* <Tab>{t("disclosure_label_2")}</Tab> */}
          <Tab>{t("disclosure_label_3")}</Tab>
          <Tab>{t("disclosure_label_4")}</Tab>
        </TabList>
        <TabPanel>
          {/* <p className="style1">
            {t("disclosure_label_1_tab_panel_line_1")} <br />
            <br />
          </p>
          <p className="style1">
            {t("disclosure_label_1_tab_panel_line_2")} <br />
            <br />
          </p>
          <p className="style1">
            {t("disclosure_label_1_tab_panel_line_3")} <br />
            <br />
          </p>
          <p className="style1">
            {t("disclosure_label_1_tab_panel_line_4")} <br />
            <br />
          </p> */}
          <p className="style1">
            {t("disclosure_label_1_tab_panel_line_1_1")} <br />
            <br />
          </p>
          <p className="style1">
            {t("disclosure_label_1_tab_panel_line_2_1")} <br />
            <br />
          </p>
          <p className="style1">
            {t("disclosure_label_1_tab_panel_line_3_1")} <br />
            <br />
          </p>
          <p className="style1">
            {t("disclosure_label_1_tab_panel_line_4_1")} <br />
            <br />
          </p>
          <p className="style1">
            {t("disclosure_label_1_tab_panel_line_5_1")} <br />
            <br />
          </p>
          <p className="style1">
            {t("disclosure_label_1_tab_panel_line_6_1")} <br />
            <br />
          </p>
          <p className="style1">
            {t("disclosure_label_1_tab_panel_line_7_1")} <br />
            <br />
          </p>
          <p className="style1">
            {t("disclosure_label_1_tab_panel_line_8_1")} <br />
            <br />
          </p>
        </TabPanel>
        {/* <TabPanel>
          <p className="style1">
            {t("disclosure_label_2_tab_panel_line_1")} <br />
            <br />
          </p>
          <p className="style1">
            {t("disclosure_label_2_tab_panel_line_2")} <br />
            <br />
          </p>
          <p className="style1">
            {t("disclosure_label_2_tab_panel_line_3")} <br />
            <br />
          </p>
        </TabPanel> */}
        <TabPanel>
          <p className="style1">
            {t("disclosure_label_3_tab_panel_line_1")} <br />
            <br />
          </p>
          <p className="style1">
            {t("disclosure_label_3_tab_panel_line_2")} <br />
            <br />
          </p>
          <p className="style1">
            {t("disclosure_label_3_tab_panel_line_3")} <br />
            <br />
          </p>
        </TabPanel>
        <TabPanel>
          <p className="style1">
            {t("disclosure_label_4_tab_panel_line_1")} <br />
            <br />
          </p>
          <p className="style1">
            {t("disclosure_label_4_tab_panel_line_2")} <br />
            <br />
          </p>
          <p className="style1">
            {t("disclosure_label_4_tab_panel_line_3")} <br />
            <br />
          </p>
          <p className="style1">
            {t("disclosure_label_4_tab_panel_line_4")} <br />
            <br />
          </p>
        </TabPanel>
      </Tabs>
      <div className="isMobile">
        <Collapsible
          tabIndex={0}
          open
          trigger={<CollapsibleTitle title={t("disclosure_label_1_1")} />}
          className="entity_style margin_table"
        >
          <p className="style1">
            {t("disclosure_label_1_tab_panel_line_1_1")} <br />
            <br />
          </p>
          <p className="style1">
            {t("disclosure_label_1_tab_panel_line_2_1")} <br />
            <br />
          </p>
          <p className="style1">
            {t("disclosure_label_1_tab_panel_line_3_1")} <br />
            <br />
          </p>
          <p className="style1">
            {t("disclosure_label_1_tab_panel_line_4_1")} <br />
            <br />
          </p>
          <p className="style1">
            {t("disclosure_label_1_tab_panel_line_5_1")} <br />
            <br />
          </p>
          <p className="style1">
            {t("disclosure_label_1_tab_panel_line_6_1")} <br />
            <br />
          </p>
          <p className="style1">
            {t("disclosure_label_1_tab_panel_line_7_1")} <br />
            <br />
          </p>
          <p className="style1">
            {t("disclosure_label_1_tab_panel_line_8_1")} <br />
            <br />
          </p>
        </Collapsible>
        {/* <Collapsible
          tabIndex={2}
          trigger={<CollapsibleTitle title={t("disclosure_label_2")} />}
          className="entity_style margin_table"
        >
          <p className="style1">
            {t("disclosure_label_2_tab_panel_line_1")} <br />
            <br />
          </p>
          <p className="style1">
            {t("disclosure_label_2_tab_panel_line_2")} <br />
            <br />
          </p>
          <p className="style1">
            {t("disclosure_label_2_tab_panel_line_3")} <br />
            <br />
          </p>
        </Collapsible> */}
        <Collapsible
          tabIndex={3}
          trigger={<CollapsibleTitle title={t("disclosure_label_3")} />}
          className="entity_style margin_table"
        >
          <p className="style1">
            {t("disclosure_label_3_tab_panel_line_1")} <br />
            <br />
          </p>
          <p className="style1">
            {t("disclosure_label_3_tab_panel_line_2")} <br />
            <br />
          </p>
          <p className="style1">
            {t("disclosure_label_3_tab_panel_line_3")} <br />
            <br />
          </p>
        </Collapsible>
        <Collapsible
          tabIndex={4}
          trigger={<CollapsibleTitle title={t("disclosure_label_4")} />}
          className="entity_style margin_table"
        >
          <p className="style1">
            {t("disclosure_label_4_tab_panel_line_1")} <br />
            <br />
          </p>
          <p className="style1">
            {t("disclosure_label_4_tab_panel_line_2")} <br />
            <br />
          </p>
          <p className="style1">
            {t("disclosure_label_4_tab_panel_line_3")} <br />
            <br />
          </p>
          <p className="style1">
            {t("disclosure_label_4_tab_panel_line_4")} <br />
            <br />
          </p>
        </Collapsible>
        <Collapsible
          tabIndex={5}
          open
          trigger={<CollapsibleTitle title={t("doo_entity_notice")} />}
          className="entity_style margin_table"
          contentOuterClassName="border-bottom-un-set"
        >
          <DooEntities></DooEntities>
        </Collapsible>
      </div>
    </div>
  );
};

export default withTranslation()(Disclosure);
