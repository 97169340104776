import React, { useState } from "react";
import Logo from "../../assets/Logo.png";
import DooPrimeLogo from "../../assets/Logo_DP_White.png";
import DooClearingLogo from "../../assets/Logo_DC_White.png";
import DooTechLogo from "../../assets/Logo_DT_White.png";
import DooFinancialLogo from '../../assets/Logo_DF_White.png'

import DooPrimeLogoColor from "../../assets/Logo_DP_Colour.png";
import DooClearingLogoColor from "../../assets/Logo_DC_Colour.png";
import DooTechLogoColor from "../../assets/Logo_DT_Colour.png";
import DooFinancialLogoColor from '../../assets/Logo_DF_Colour.png'

import FacebookLogo from "../../assets/Icon_Facebook.png";
import LinkedlnLogo from "../../assets/Icon_LinkedIn.png";
import YoutubeLogo from "../../assets/Icon_YouTube.png";
import IntagramLogo from "../../assets/Icon_Instagram.png";

import EmailIcon from "../../assets/Icon_Email.png"
import PhoneIcon from "../../assets/Icon_Phone.png"

import Disclosure from "./disclosure";
import { withTranslation } from 'react-i18next';
import Tester from "./collapsible"

import Collapsible from 'react-collapsible';
import CollapsibleTitle from "./collapsibleTitle"

const socialMediaSet = [
  {
    alt: "doo linkin link",
    href: "https://www.linkedin.com/company/doo-holding-group-limited/",
    img: LinkedlnLogo
  },
  {
    alt: "doo facebook link",
    href: "https://www.facebook.com/DooGroup",
    img: FacebookLogo
  },
  {
    alt: "doo instagram link",
    href: "https://www.instagram.com/doo_group/",
    img: IntagramLogo
  },
  {
    alt: "doo youtube link",
    href: "https://www.youtube.com/@doogroup6728",
    img: YoutubeLogo
  },
]

const logoSet = [
  {
    alt: "doo financial logo",
    href: "https://www.doofinancial.com",
    colorImg: DooFinancialLogoColor,
    img: DooFinancialLogo
  },
  {
    alt: "doo clearing logo",
    href: "https://www.dooclearing.com",
    colorImg: DooClearingLogoColor,
    img: DooClearingLogo
  },
  {
    alt: "doo prime logo",
    href: "https://www.dooprime.com",
    colorImg: DooPrimeLogoColor,
    img: DooPrimeLogo
  },
  {
    alt: "doo tech logo",
    href: "https://doo.tech",
    colorImg: DooTechLogoColor,
    img: DooTechLogo
  },
]
const careerSet = [
  { title: "latest_jobs", href: "https://career.doogroup.com/career/#latestjobs" },
  { title: "our_jobs", href: "https://career.doogroup.com/career/#whychoosedoogroup" },
  { title: "our_life", href: "https://career.doogroup.com/career/#lifeatdoogroup" },
  { title: "testimonials", href: "https://career.doogroup.com/career/#testimonials" },
]
const pressCenterSet = [
  { title: "news", href: "" },
  { title: "events", href: "" },
  { title: "notice", href: "" },
  { title: "esg", href: "" },
]


const Contact = ({ t }) => {
  return (
    <footer id="contact-us">
      <div className="container">
        <div className="left">
          <div className="about-us">
            <figure style={{ width: "149px", height: "32px" }}>
              <img
                className="responsive-img"
                src={Logo}
                alt="doo holding logo"
              />
            </figure>
            <div className="brand-row" >
              {logoSet.map(item => (
                <BrandLogo key={item.alt} {...item} />
              ))}
            </div>
          </div>
          <div className="grid-container-6 right" style={{ rowGap: "1rem" }}>
            <div style={{ gridColumn: "span 2/ span 2" }}></div>
            <div className="isDesktop">
              <div className="career">
                <p>{t("career")}</p>
                {
                  careerSet.map(item => (
                    <a href={item.href} target="_blank" rel="noopener noreferrer" key={item.title}>{t(item.title)}</a>
                  ))
                }
              </div>
            </div>
            <div className="table_border1  isMobile">
              <Collapsible trigger={
                <CollapsibleTitle title={t('career')} />}>
                <div className="career" style={{ paddingBottom: "16px" }}>
                  {
                    careerSet.map(item => (
                      <a href={item.href} target="_blank" rel="noopener noreferrer" key={item.title}>{t(item.title)}</a>
                    ))
                  }
                </div>
              </Collapsible>
            </div>

            <div className="career" style={{ display: "none" }}>
              <p>{t("press_center")}</p>
              {
                pressCenterSet.map(item => (
                  <a href={item.href} key={item.title}>{t(item.title)}</a>
                ))
              }

            </div>

            <div className="social-media">

            </div>
            <div className="contact-us" style={{ gridColumn: "span 2/ span 2" }} >
              <p>{t('doo_contact')}</p>
              <div className="contact-us-row">
                <img src={EmailIcon}
                  alt="email icon"
                  style={{
                    width: "16px",
                    height: "16px",
                  }}></img>
                <p>{t('contact_us_email')}</p>

                <img src={PhoneIcon}
                  alt="email icon"
                  style={{
                    width: "16px",
                    height: "16px",
                  }}></img>
                <p> {t('contact_us_num')}</p>

                <span></span>
                <p> {t('contact_us_num2')}</p>
              </div>
            </div>
            <div style={{ gridColumn: "span 4 / span 4" }}></div>
            <div style={{ gridColumn: "span 2/ span 2" }}>
              <p style={{
                marginBottom: "0.5rem",
                marginTop: "0.5rem"
              }}>{t('doo_follow')}</p>
              {socialMediaSet.map(item => (
                <SocialMediaIcon key={item.alt} {...item} />
              ))}
            </div>
          </div>
        </div>


        <div className="grid-container-1 border_btm">
          <Disclosure />
        </div>
      </div>
      <Tester />
      <div className="container">
        <p className="copyright">© 2023 Doo Group. All Rights Reserved.</p>
      </div>
    </footer >
  );
};


const SocialMediaIcon = ({ alt, img, href }) => {
  const [hover, setHover] = useState(false)
  const handleMouseOver = () => {
    setHover(true)
  }
  const handleMouseOut = () => {
    setHover(false)
  }
  return (
    <a href={href} target="_blank" rel="noopener noreferrer" onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} >
      <img
        key={alt}
        className="responsive-img"
        src={img}
        style={{
          opacity: hover ? "100%" : "50%",
          width: "20px",
          height: "20px",
          marginRight: "2rem"
        }}
        alt={alt}
      />
    </a >
  )
}
const BrandLogo = ({ alt, img, href, colorImg }) => {
  const [hover, setHover] = useState(false)
  const handleMouseOver = () => {
    setHover(true)
  }
  const handleMouseOut = () => {
    setHover(false)
  }
  return (
    <a href={href} target="_blank" rel="noopener noreferrer" onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} >
      <img
        className="responsive-img isDesktop"
        src={hover ? colorImg : img}
        style={{
          opacity: hover ? "100%" : "50%",
        }}
        alt={alt}
      />
      <img
        className="responsive-img isMobile"
        src={colorImg}
        alt={alt}
      />
    </a >
  )

}



export default withTranslation()(Contact);
