import React from "react";
import { withTranslation } from "react-i18next";
import ImageValue1 from "../../assets/MuLanding/Image-Values1.png";
import ImageValue2 from "../../assets/MuLanding/Image-Values2.png";
import ImageValue3 from "../../assets/MuLanding/Image-Values3.png";
import RedArrowRight from "../../assets/MuLanding/icon-arrow-right-red.png";
import WhiteArrowRight from "../../assets/MuLanding/icon-arrow-right-white.webp";
import { useTranslation } from "react-i18next";

const Title = ({ title1, title2, description }) => {
  const { i18n } = useTranslation();

  return (
    <>
      <div className="title">
        {title1} {i18n.language !== "en" && <br></br>}
        <span
          style={{
            fontFamily: i18n.language === "en" ? "Oswald" : "YaHei",
          }}
        >
          {title2}
        </span>
      </div>
      <div className="description">{description}</div>
    </>
  );
};

const ThreeImageBanner = ({ t }) => {
  const { i18n } = useTranslation();

  return (
    <div className="three-image-banner-main-container">
      <MobileBanner
        {...{
          className: "isMobile",
          img: ImageValue1,
          content: (
            <Title
              {...{
                title1: t("fisrt_image_title_1"),
                title2: t("fisrt_image_title_2"),
                description: t("fisrt_image_description"),
              }}
            />
          ),
        }}
      />
      <MobileBanner
        {...{
          className: "isMobile red-bg",
          red: true,
          img: ImageValue2,
          content: (
            <Title
              {...{
                title1: t("second_image_title_1"),
                title2: t("second_image_title_2"),
                description: t("second_image_description"),
              }}
            />
          ),
        }}
      />
      <MobileBanner
        {...{
          className: "isMobile",
          img: ImageValue3,
          content: (
            <Title
              {...{
                title1: t("third_image_title_1"),
                title2: t("third_image_title_2"),
                description: t("third_image_description"),
              }}
            />
          ),
        }}
      />
      <div className="isDesktop">
        <div>
          <div className="col-span-7 three-image-banner-image-content">
            <img src={ImageValue1} alt="" />
          </div>
          <div className="col-span-4 three-image-banner-content" data-aos="fade-right">
            <div>
              <img src={RedArrowRight} alt="red arrow" className="arrow-icon" />
              <div>
                <Title
                  {...{
                    title1: t("fisrt_image_title_1"),
                    title2: t("fisrt_image_title_2"),
                    description: t("fisrt_image_description"),
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-span-1"></div>
        </div>
      </div>

      <div className="red-bg isDesktop">
        <div>
          <div className="col-span-1"></div>
          <div
            className="col-span-4 three-image-banner-content"
            data-aos="fade-right"
            style={{ minHeight: "600px" }}
          >
            <div>
              <img src={WhiteArrowRight} alt="white arrow" className="arrow-icon" />
              <div>
                <Title
                  {...{
                    title1: t("second_image_title_1"),
                    title2: t("second_image_title_2"),
                    description: t("second_image_description"),
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-span-7 three-image-banner-image-content-float">
            <img src={ImageValue2} alt="" />
          </div>
        </div>
      </div>
      <div className="isDesktop">
        <div>
          <div className="col-span-7 three-image-banner-image-content-float">
            <img src={ImageValue3} alt="" style={{ bottom: "unset", top: "-4rem" }} />
          </div>
          <div className="col-span-4 three-image-banner-content" data-aos="fade-right">
            <div>
              <img src={RedArrowRight} alt="red arrow" className="arrow-icon" />
              <div>
                <div className="title">
                  {t("third_image_title_1")}
                  {i18n.language !== "en" && <br></br>}
                  <span
                    style={{
                      fontFamily: i18n.language === "en" ? "Oswald" : "YaHei",
                    }}
                  >
                    {t("third_image_title_2")}
                  </span>
                </div>
                <div className="description" style={{ paddingBottom: "17vw" }}>
                  {t("third_image_description")}
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-1"></div>
        </div>
      </div>
    </div>
  );
};

const MobileBanner = ({ className, red, content, img }) => {
  return (
    <div className={className}>
      <div>
        <div className="col-span-1"></div>
        <div className="col-span-4 three-image-banner-content" data-aos="fade-right">
          <div>
            {red ? (
              <img src={WhiteArrowRight} alt="white arrow" className="arrow-icon" />
            ) : (
              <img src={RedArrowRight} alt="red arrow" className="arrow-icon" />
            )}
            <div>{content}</div>
          </div>
        </div>
        <div className="col-span-7 three-image-banner-image-content">
          <img src={img} alt="" />
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(ThreeImageBanner);
