import React from "react";
import videojs from "video.js";

export default class VideoPlayer extends React.Component {
  // Instantiate a Video.js player when the component mounts
  componentDidMount() {
    this.player = videojs(this.videoNode, this.props, () => {
      this.props.onReady(this.player)
    });
  }

  // Dispose the player when the component will unmount
  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  // Wrap the player in a `div` with a `data-vjs-player` attribute, so Video.js
  // won't create additional wrapper in the DOM.
  //
  // See: https://github.com/videojs/video.js/pull/3856
  render() {
    return (
      <video
        ref={(node) => (this.videoNode = node)}
        className="video-js mu-video"
        style={{
          height: "100%",
          width: "100%",
          background: "white",
        }}
      >
      </video>
    );
  }
}
