import React, { useEffect } from "react";
import EmailTemplate from "./components/emailTemplate";
import MainComponent from "./components/mainComponent";
import MULading from "./components/muLanding/index";
import Mask from "./components/mask";
import Page404 from "./components/page404";
import { BrowserRouter as Router, Route, Switch, Redirect, useLocation } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import "video.js/dist/video-js.css";
import { getCookie, setCookie } from "./utils/cookie";
const RemoveTrailingSlash = ({ ...rest }) => {
  const location = useLocation();

  // If the last character of the url is '/'
  if (location.pathname.match("/.*/$")) {
    return (
      <Redirect
        replace
        {...rest}
        to={{
          pathname: location.pathname.replace(/\/+$/, ""),
          search: location.search,
        }}
      />
    );
  } else return null;
};

// 路由拦截，如果微信或QQ打开，则跳转至遮罩页面
const PrivateRoute = ({ component: Component, showMask, ...rest }) => (
  <Route {...rest} render={(props) => (showMask ? <Mask /> : <Component {...props} />)} />
);

const App = () => {
  let collectionId = getCookie("collection_id");
  if (!collectionId) {
    let gid = getCookie("_ga");
    if (gid) {
      collectionId = gid.substring(6);
    } else {
      collectionId = "";
      for (let i = 0; i < 9; i++) {
        collectionId += Math.floor(Math.random() * 10);
      }
      collectionId += "." + (Date.now() + "").substring(0, 10);
    }
    setCookie("collection_id", collectionId, 365, "/", "", "");
  }

  useEffect(() => {
    AOS.init();
  }, []);

  // 判断是否qq或者微信打开
  const ua = navigator.userAgent.toLowerCase();
  const showMask = !!/micromessenger/i.test(ua) || !!(/ qq/i.test(ua) && !/ qqbrowser/i.test(ua));
  return (
    <Router>
      <RemoveTrailingSlash />
      <Switch>
        <Route path="/mask" component={Mask} />
        <PrivateRoute path="/disclosure/email" component={EmailTemplate} showMask={showMask} />
        <PrivateRoute exact path="/en" component={MainComponent} showMask={showMask} />
        <PrivateRoute exact path="/zh" component={MainComponent} showMask={showMask} />
        <PrivateRoute exact path="/tc" component={MainComponent} showMask={showMask} />
        <PrivateRoute
          exact
          path="/en/partnership/manutd"
          component={MULading}
          showMask={showMask}
        />
        <PrivateRoute
          exact
          path="/zh/partnership/manutd"
          component={MULading}
          showMask={showMask}
        />
        <PrivateRoute
          exact
          path="/tc/partnership/manutd"
          component={MULading}
          showMask={showMask}
        />
        <Route exact path="/">
          <Redirect to="/en" />
        </Route>
        <Route path="*" component={Page404} />
      </Switch>
    </Router>
  );
};

export default App;
