import React, { useEffect, useState, useRef } from "react";
import { withTranslation } from "react-i18next";

import ImageArticle1 from "../../assets/MuLanding/Image-Article1.png";

const removeHtmlTag = (string) => {
  return string.replaceAll(/(<([^>]+)>)/gi, "");
};

const ThreeBlogRow = ({ t, i18n }) => {
  const [blogData, setBlogData] = useState([]);
  const scrollRef = useRef();
  const blogWidth = 452 + 12;
  const [scrollPosition, setScrollPosition] = useState({
    left: true,
    right: false,
  });
  const [overflow, setOverflow] = useState(false);

  //to get data from wp base on tag
  const handleGetBlogData = async () => {
    try {
      const data = await getBlogData(i18n.language);
      const imageList = [];
      setBlogData(
        data.map((item) => {
          imageList.push(getImage(i18n.language, item.featured_media));
          return {
            title: removeHtmlTag(item.title.rendered),
            description: removeHtmlTag(item.excerpt.rendered),
            date: item.date.split("T")[0],
            featuredMedia: item.featured_media,
            href: item.link,
          };
        })
      );

      const imageResult = await Promise.all(imageList);
      if (imageResult.length) {
        setBlogData((prev) =>
          prev.map((item, index) => ({
            ...item,
            imgHref: imageResult[index].link,
          }))
        );
      }
    } catch (error) {
      console.log("🚀 ~ file: threeBlogRow.jsx:14 ~ handleGetBlogData ~ error:", error);
    } finally {
      handleCheckOverflow();
    }
  };
  useEffect(() => {
    handleGetBlogData();
  }, [i18n.language]);

  const handleScrollEvent = () => {
    if (scrollRef.current.scrollLeft === 0) setScrollPosition({ left: true, right: false });
    else if (
      scrollRef.current.scrollLeft + scrollRef.current.offsetWidth >=
      scrollRef.current.scrollWidth
    )
      setScrollPosition({ left: false, right: true });
    else setScrollPosition({ left: false, right: false });
  };

  const handleCheckOverflow = () => {
    if (scrollRef.current.scrollWidth > scrollRef.current.clientWidth) setOverflow(true);
    else setOverflow(false);
  };
  useEffect(() => {
    if (scrollRef && scrollRef.current) {
      scrollRef.current.addEventListener("scroll", handleScrollEvent);
      window.addEventListener("resize", handleCheckOverflow);
    }

    return () => {
      scrollRef.current.removeEventListener("scroll", handleScrollEvent);
      window.removeEventListener("resize", handleScrollEvent);
    };
  }, []);

  const handleNextOnClick = () => {
    if (scrollRef.current) {
      scrollRef.current.scroll({
        left: scrollRef.current.scrollLeft + blogWidth,
        behavior: "smooth",
      });
    }
  };

  const handlePreviousOnClick = () => {
    if (scrollRef.current) {
      scrollRef.current.scroll({
        left: scrollRef.current.scrollLeft - blogWidth,
        behavior: "smooth",
      });
    }
  };

  return (
    <div
      className={`three-blog-row ${overflow ? "three-blog-row-margin-bottom" : ""}`}
      style={{
        marginRight: overflow ? "unset" : "auto",
        marginLeft: overflow ? "unset" : "auto",
      }}
    >
      <div ref={scrollRef}>
        {blogData.length
          ? blogData.map((item, index) => (
              <Blog
                first={index === 0 && overflow}
                key={item.title}
                image={item.imgHref || ImageArticle1}
                alt=""
                title={i18n.language === "en" ? item.title.toLowerCase() : item.title}
                description={item.description}
                href={item.href}
                date={item.date}
              />
            ))
          : ""}
      </div>
      {overflow && (
        <>
          <button
            className="next-button"
            onClick={handleNextOnClick}
            disabled={scrollPosition.right}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="vector-svg"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
            </svg>
          </button>

          <button
            className="previous-button"
            onClick={handlePreviousOnClick}
            disabled={scrollPosition.left}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="vector-svg"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
            </svg>
          </button>
        </>
      )}
    </div>
  );
};

const Blog = ({ image, alt, title, description, href, date, first }) => {
  return (
    <a href={href} target="blank" className={`${first ? "first-blog" : ""}`}>
      <div className="blog-content">
        <img src={image} alt={alt} />
        <div>
          <div className="title">{title}</div>
          <div className="description line-clamp-3">{description}</div>
          <div className="date">{date}</div>
        </div>
      </div>
    </a>
  );
};

const getBlogData = async (lang) => {
  let link;
  if (lang === "en") {
    link = "https://www.dooprimenews.com/wp-json/wp/v2/posts?tags=623";
  } else {
    link = "https://www.dooprimenews.net/wp-json/wp/v2/posts?tags=676";
  }

  const res = await fetch(link);
  if (res.status >= 200 && res.status < 300) {
    return res.json();
  } else {
    throw new Error();
  }
};

const getImage = async (lang, id) => {
  let link;
  if (lang === "en") {
    link = `https://www.dooprimenews.com/wp-json/wp/v2/media/${id}`;
  } else {
    link = `https://www.dooprimenews.net/wp-json/wp/v2/media/${id}`;
  }
  const res = await fetch(link);
  if (res.status >= 200 && res.status < 300) {
    return res.json();
  } else {
    throw new Error();
  }
};
export default withTranslation()(ThreeBlogRow);
