import React from "react";
import FootImage from "../../assets/MuLanding/Image-Footer.png";
import FootImageMobile from "../../assets/MuLanding/mobile/Image-Footer.png";
const FootImageRow = () => {
  return (
    <div className="foot-image">
      <img src={FootImage} alt="" className="desktop" />
      <img src={FootImageMobile} alt="" className="mobile" />
    </div>
  );
};

export default FootImageRow;
