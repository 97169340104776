import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { getCountryCode } from "../../api/location";

const DooEntities = ({ t }) => {
  let [isDisplayDooEntities, setIsDisplayDooEntities] = useState(false);
  const EUcountryList = [
    "AX",
    "AD",
    "AT",
    "BE",
    "BG",
    "IC",
    "HR",
    "CZ",
    "CY",
    "DK",
    "EE",
    "FI",
    "FR",
    "DE",
    "GI",
    "GR",
    "HU",
    "IE",
    "IT",
    "LV",
    "LT",
    "LU",
    "MT",
    "MC",
    "NL",
    "NO",
    "PL",
    "PT",
    "RO",
    "SM",
    "SK",
    "SI",
    "ES",
    "SE",
    "GB",
    "RS",
    "VA",
  ];
  useEffect(() => {
    const getCountry = async () => {
      const data = await getCountryCode();
      if (!EUcountryList.includes(data)) {
        setIsDisplayDooEntities(true);
      }
    };

    getCountry();
  }, []);

  return (
    <div>
      <div className="grid-container-2">
        <div className="footer-left">
          <p className="name unset-margin">{t("brands_elish_elish")}</p>
          <p className="detail">{t("brands_elish_elish_content1")}</p>
          <p className="detail">
            {t("brands_elish_elish_content2")}
            <span className="detail-highlight">
              {" "}
              {t("brands_elish_elish_content2.1")}
            </span>
          </p>
          <p className="detail">{t("brands_elish_elish_content3")}</p>
          {isDisplayDooEntities === true && (
            <p className="website">
              <a href="https://www.doofinancial.com/" target="_blank">
                www.doofinancial.com{" "}
              </a>
            </p>
          )}
          {isDisplayDooEntities === true && (
            <p className="website">
              <a
                href="https://www.elish.com/"
                target="_blank"
                rel="noopener noreferrer">
                www.elish.com{" "}
              </a>
            </p>
          )}
        </div>

        <div className="footer-right">
          <p className="name">{t("brands_doo_clearing")}</p>
          <p className="detail">
            {t("brands_doo_clearing_content1")}
            <span className="detail-highlight">
              {" "}
              {t("brands_doo_clearing_content1.1")}
            </span>
          </p>
          <p className="detail">
            {t("brands_doo_clearing_content2")}
            <span className="detail-highlight">
              {" "}
              {t("brands_doo_clearing_content2.1")}
            </span>
          </p>
          {isDisplayDooEntities === true && (
            <p className="website">
              <a href="http://www.dooclearing.co.uk/">www.dooclearing.co.uk</a>
            </p>
          )}
        </div>

        <div className="footer-left">
          <p className="name">{t("brands_australia")}</p>
          <p className="detail">
            {t("brands_australia_content1")}
            <span className="detail-highlight">
              {" "}
              {t("brands_australia_content1.1")}
            </span>
          </p>
          <p className="detail">
            {t("brands_australia_content2")}
            <span className="detail-highlight">
              {" "}
              {t("brands_australia_content2.1")}
            </span>
          </p>
          {isDisplayDooEntities === true && (
            <p className="website">
              <a href="https://www.doofinancial.com.au/">
                www.doofinancial.com.au
              </a>
            </p>
          )}
        </div>

        <div className="footer-right">
          <p className="name">{t("brands_doo_exchange_au")}</p>
          <p className="detail">
            {t("brands_doo_exchange_au_content1")}
            <span className="detail-highlight">
              {" "}
              {t("brands_doo_exchange_au_content1.1")}
            </span>
          </p>
          <p className="detail">
            {t("brands_doo_exchange_au_content2")}
            <span className="detail-highlight">
              {" "}
              {t("brands_doo_exchange_au_content2.1")}
            </span>
          </p>
          {isDisplayDooEntities === true && (
            <p className="website">
              {/* <a href="https://www.dooexchange.com/">www.dooexchange.com</a>*/}
            </p>
          )}
        </div>

        <div className="footer-left">
          <p className="name">{t("brands_doo_financial_hk")}</p>
          <p className="detail">
            {t("brands_doo_financial_hk_content1")}
            <span className="detail-highlight">
              {" "}
              {t("brands_doo_financial_hk_content1.1")}
            </span>
          </p>
          <p className="detail">
            {t("brands_doo_financial_hk_content2")}
            <span className="detail-highlight">
              {" "}
              {t("brands_doo_financial_hk_content2.1")}
            </span>
          </p>
          <p className="website">
            {/* <a href="https://www.doofinancial.com.au/">
              www.doofinancial.com.au
            </a> */}
          </p>
        </div>

        <div className="footer-right">
          <p className="name">{t("brands_doo_wealth_management")}</p>
          <p className="detail">
            {t("brands_doo_wealth_management_content1")}
            <span className="detail-highlight">
              {" "}
              {t("brands_doo_wealth_management_content1.1")}
            </span>
          </p>
          <p className="detail">
            {t("brands_doo_wealth_management_content2")}
            <span className="detail-highlight">
              {" "}
              {t("brands_doo_wealth_management_content2.1")}
            </span>
          </p>
          <p className="website">
            {/* <a href="https://www.dooexchange.com/">www.dooexchange.com</a>*/}
          </p>
        </div>

        <div className="footer-left">
          <p className="name">{t("brands_doo_holdgroup")}</p>
          <p className="detail">
            {t("brands_doo_holdgroup_content1")}
            <span className="detail-highlight">
              {" "}
              {t("brands_doo_holdgroup_content1.1")}
            </span>
          </p>
          <p className="detail">
            {t("brands_doo_holdgroup_content2")}
            <span className="detail-highlight">
              {" "}
              {t("brands_doo_holdgroup_content2.1")}
            </span>
          </p>
          {isDisplayDooEntities === true && (
            <p className="website">
              <a href="https://www.dooexchange.com/">www.dooexchange.com</a>
            </p>
          )}
        </div>

        <div className="footer-right">
          <p className="name">{t("brands_doo_properties")}</p>
          <p className="detail">
            {t("brands_doo_properties_content1")}
            <span className="detail-highlight">
              {" "}
              {t("brands_doo_properties_content1.1")}
            </span>
          </p>
          <p className="detail">
            {t("brands_doo_properties_content2")}
            <span className="detail-highlight">
              {" "}
              {t("brands_doo_properties_content2.1")}
            </span>
          </p>
          <p className="website">
            {/* <a href="https://www.doo.tech/">www.dooclearing.co.uk</a> */}
          </p>
        </div>

        <div className="footer-left">
          <p className="name">{t("brands_doo_consulting")}</p>
          <p className="detail">
            {t("brands_doo_consulting_content1")}
            <span className="detail-highlight">
              {" "}
              {t("brands_doo_consulting_content1.1")}
            </span>
          </p>
          <p className="detail">
            {t("brands_doo_consulting_content2")}
            <span className="detail-highlight">
              {" "}
              {t("brands_doo_consulting_content2.1")}
            </span>
          </p>
          <p className="website">
            {/* <a href="https://www.doo.tech/">www.dooclearing.co.uk</a> */}
          </p>
        </div>

        <div className="footer-right">
          <p className="name">{t("brands_seychelles")}</p>
          <p className="detail">
            {t("brands_seychelles_content1")}
            <span className="detail-highlight">
              {" "}
              {t("brands_seychelles_content1.1")}
            </span>
          </p>
          <p className="detail">
            {t("brands_seychelles_content2")}
            <span className="detail-highlight">
              {" "}
              {t("brands_seychelles_content2.1")}
            </span>
          </p>
          {isDisplayDooEntities === true && (
            <p className="website">
              <a href="https://www.dooprime.sc/">www.dooprime.sc</a>
            </p>
          )}
        </div>

        <div className="footer-left">
          <p className="name">{t("brands_labuan")}</p>
          <p className="detail">
            {t("brands_labuan_content1")}
            <span className="detail-highlight">
              {" "}
              {t("brands_labuan_content1.1")}
            </span>
          </p>
          <p className="detail">
            {t("brands_labuan_content2")}
            <span className="detail-highlight">
              {" "}
              {t("brands_labuan_content2.1")}
            </span>
          </p>
        </div>

        <div className="footer-right">
          <p className="name">{t("brands_doo_mauritius")}</p>
          <p className="detail">
            {t("brands_doo_mauritius_content1")}
            <span className="detail-highlight">
              {" "}
              {t("brands_doo_mauritius_content1.1")}
            </span>
          </p>
          <p className="detail">
            {t("brands_doo_mauritius_content2")}
            <span className="detail-highlight">
              {" "}
              {t("brands_doo_mauritius_content2.1")}
            </span>
          </p>
          {isDisplayDooEntities === true && (
            <p className="website">
              <a href="https://www.dooprime.mu/">www.dooprime.mu </a>
            </p>
          )}
        </div>

        <div className="footer-left">
          <p className="name">{t("brands_doo_vantau")}</p>
          <p className="detail">
            {t("brands_doo_vantau_content1")}
            <span className="detail-highlight">
              {" "}
              {t("brands_doo_vantau_content1.1")}
            </span>
          </p>
          <p className="detail">
            {t("brands_doo_vantau_content2")}
            <span className="detail-highlight">
              {" "}
              {t("brands_doo_vantau_content2.1")}
            </span>
          </p>
          {isDisplayDooEntities === true && (
            <p className="website">
              <a href="https://www.dooprime.com/">www.dooprime.com</a>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(DooEntities);
