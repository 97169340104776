const axios = require("axios");

export const getCountryCode = async () => {
  try {
    const { data: { data } } = await axios.get(
      "https://frontapi.finpoints.tech"
    );
    return data.iso_country_code;
  } catch (error) {
    return "";
  }
};
