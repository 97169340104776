import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const LangChange = ({ light }) => {
  const { i18n } = useTranslation();
  const getColor = (lang) => {
    return { color: lang === i18n.language ? (light === true ? "#e03323" : "#000") : "#999" };
  };

  return (
    <li className="navbar-item">
      <NavLink
        to="/zh"
        onClick={() => {
          i18n.changeLanguage("zh");
        }}
        style={getColor("zh")}
      >
        简
      </NavLink>
      /
      <NavLink
        to="/tc"
        style={getColor("tc")}
        onClick={() => {
          i18n.changeLanguage("tc");
        }}
      >
        繁
      </NavLink>
      /
      <NavLink
        to="/en"
        style={getColor("en")}
        onClick={() => {
          i18n.changeLanguage("en");
        }}
      >
        EN
      </NavLink>
    </li>
  );
};

export default LangChange;
