import React, { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";

import leftImage from "../../assets/MuLanding/Image-Intro.webp";
import arrowRight from "../../assets/MuLanding/icon-arrow-right-white.webp";
import ImageIntroMobile from "../../assets/MuLanding/mobile/Image-Intro.png";

const ImageWithTextBox = ({ t }) => {
  const [paddingBottom, setPaddingBottom] = useState(0);
  const ref = useRef();

  const handleWindowChangeSize = () => {
    if (window.innerWidth <= 600 && ref.current) {
      setPaddingBottom(ref.current.clientHeight + 30);
    }
  };
  useEffect(() => {
    if (typeof window !== "undefined") {
      handleWindowChangeSize();
      window.addEventListener("resize", handleWindowChangeSize);
    }
    return () => {
      window.removeEventListener("resize", handleWindowChangeSize);
    };
  }, []);

  return (
    <>
      <div className="image-with-text-box-container">
        <img src={leftImage} alt="winning" data-aos="fade-left"></img>
        <div className="content" data-aos="fade-right">
          <img src={arrowRight} alt="arrow right"></img>
          <div>
            <div className="image-with-text-box-title">
              <span style={{ fontWeight: "bold" }}>
                {t("image_with_text_box_title")}
              </span>
            </div>
            <div className="image-with-text-box-description">
              {t("image_with_text_box_description")}
            </div>
          </div>
        </div>
      </div>
      <div
        className="image-with-text-box-container-mobile"
        style={{ paddingBottom: paddingBottom }}
      >
        <div>
          <img src={ImageIntroMobile} alt="" />
          <div className="red-content" ref={ref}>
            <div className="title">{t("image_with_text_box_title")}</div>
            <div className="description">
              {t("image_with_text_box_description")}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(ImageWithTextBox);
