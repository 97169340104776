import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Header from "./header";
import LangBar from "./langBar";
import Contact from "../contact";
import ImageWithTextBox from "./imageWithTextBox";
import Banner from "./banner";
import { withTranslation } from "react-i18next";
import "./style.css";
import FootImageRow from "./footImageRow";
import CenterBanner from "./centerBanner";
import CenterTitle from "./centerTitle";
import ThreeImageBanner from "./threeImageBanner";
import ThreeImageRow from "./threeImageRow";
import ThreeBlogRow from "./threeBlogRow";
import { getCountryCode } from "../../api/location";

const MainComponent = ({ t, i18n, history }) => {
  const handleLanguageChangeBaseOnCountry = async () => {
    //china, CN -> sc
    //taiwan, TW -> tc
    //hong kong, HK ->tc
    try {
      const countryCode = await getCountryCode();
      if (countryCode === "CN") {
        if (i18n.language !== "zh") {
          i18n.changeLanguage("zh");
          history.replace("/zh/partnership/manutd")
        }
      } else if (countryCode === "TW" || countryCode === "HK") {
        if (i18n.language !== "tc") {
          i18n.changeLanguage("tc");
          history.replace("/tc/partnership/manutd")
        }
      } else {
        if (i18n.language !== "en") {
          i18n.changeLanguage("en");
          history.replace("/en/partnership/manutd")
        }
      }
    } catch (error) {
      console.log("🚀 ~ file: index.jsx:28 ~ handleLanguageChangeBaseOnCountry ~ error:", error);
    }
  };

  useEffect(() => {
    handleLanguageChangeBaseOnCountry();
    if (typeof window !== undefined) {
      window.LiveChatWidget.on("ready", () => {
        window.LiveChatWidget.call("hide");
      });
    }
    return () => {};
  }, []);

  return (
    <>
      <Helmet>
        <title>{t("seo_title")}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <div className="app" id="about">
        <div className="sticky-header">
          <LangBar />
          <Header />
        </div>
        <div className="padding-sticky-header"></div>
        <Banner />
        <ImageWithTextBox />

        <div className="grey-bg scrollMargin" id="milestones">
          <CenterTitle title={t("first_title")} description={t("first_description")} />
          <ThreeImageRow />
        </div>
        <div id="values" className="scrollMargin">
          <CenterTitle title={t("second_title")} description={t("second_description")} />
          <ThreeImageBanner />
          <CenterBanner />
        </div>
        <div id="highlights" className="scrollMargin">
          <CenterTitle title={t("third_title")} description={t("third_description")} />
          <ThreeBlogRow />
        </div>
        <FootImageRow />
        <Contact />
      </div>
    </>
  );
};

export default withTranslation()(MainComponent);
