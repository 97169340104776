import React from "react";
import { withTranslation } from "react-i18next";
import ImageMobile from "../../assets/MuLanding/mobile/Image-Future.png";

const CenterBanner = ({ t, i18n }) => {

  return (
    <div className="landing-banner center-banner">
      <h2 className="center-banner-title" data-aos="fade-up">
        {i18n.language === "en" ? (
          <>
            <span className="center-title-1">{t("center_title_1")}</span>
            <span className="center-title-2">{t("center_title_2")}</span>
          </>
        ) : (
          <>
            <span className="landing-title-cn-1">{t("center_title_1")}</span>
            <span className="landing-title-cn-2">{t("center_title_2")}</span>
          </>
        )}
      </h2>
      <h1 className="center-banner-description">{t("center_description")}</h1>
      <img src={ImageMobile} alt="" className="center-banner-image" />
    </div>
  );
};

export default withTranslation()(CenterBanner);
